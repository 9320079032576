import "tachyons";
import "./main.css";
// Register a listener for postMessage and iframe, that will communicate
// the content size. We could probably write this ourselves, but the dependency
// is more tested :)
import "iframe-resizer/js/iframeResizer.contentWindow";

// The Main (huh) Elm program
import { Elm } from "./Main.elm";

// has top navigation, hidden heading:
const STANDALONE_MODE = "Standalone";
// no top nav, visible heading:
const EMBEDDED_MODE = "Embedded";
// no top nav, hidden heading:
const STRIPPED_MODE = "Stripped";

function init() {
  const app = Elm.Main.init({
    node: document.getElementById("root"),
    // Find the flags depending on query parameters
    // This allows us to use one index html for both embeds and
    // standalone
    flags: getFlags(),
  });

  if (app.ports) {
  }
}

/**
 * Figure out which display mode flags the app will have, depending
 * on the `displayMode` and `hideSeasonSelector` query parameters.
 * `displayMode` to STANDALONE_MODE.
 */
function getFlags() {
  if (!"URL" in window) {
    return { displayMode: STANDALONE_MODE };
  }

  const url = new URL(window.location.toString());

  const rawDisplayMode = url.searchParams.get("displayMode");
  const hideFilter = parseBooleanParam(url.searchParams.get("hideFilter"));
  const displayMode = parseDisplayMode(rawDisplayMode);
  const rawHideSeasonSelector = url.searchParams.get("hideSeasonSelector");
  const hideSeasonSelector = parseBooleanParam(rawHideSeasonSelector);

  //displayOnlyCurrentSeason parameter is an alias to hideSeasonSelector
  // it's here so we can gracefully rename this parameter on .com
  // once thats done, this parameter can be removed
  const rawDisplayOnlyCurrentSeason = url.searchParams.get(
    "displayOnlyCurrentSeason"
  );
  const displayOnlyCurrentSeason = parseBooleanParam(
    rawDisplayOnlyCurrentSeason
  );

  return {
    hideFilter,
    displayMode,
    hideSeasonSelector: hideSeasonSelector || displayOnlyCurrentSeason,
  };
}

function parseDisplayMode(displayMode) {
  switch (displayMode) {
    case "standalone":
      // TODO: what is this variable for? It is not declared, not referenced anywhere
      parsedDisplayMode = STANDALONE_MODE;

    case "embedded":
      return EMBEDDED_MODE;

    case "stripped":
      return STRIPPED_MODE;

    default:
      return STANDALONE_MODE;
  }
}

function parseBooleanParam(hideSeasonSelector) {
  switch (hideSeasonSelector) {
    case "true":
      return true;

    case "false":
      return false;

    default:
      return false;
  }
}

init();
